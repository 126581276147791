@keyframes levels-pulse {
  0% {
    height: 30%;
    opacity: 0.5; }
  20% {
    height: 100%;
    opacity: 1; }
  35% {
    height: 30%;
    opacity: 0.5; }
  55% {
    height: 100%;
    opacity: 1; }
  70% {
    height: 50%;
    opacity: 0.5; }
  80% {
    height: 100%;
    opacity: 1; }
  100% {
    height: 30%;
    opacity: 0.5; } }
.rai-levels .rai-levels-container {
  text-align: left;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  animation-duration: inherit; }
.rai-levels .rai-bar {
  bottom: 0;
  display: inline-block;
  vertical-align: bottom;
  width: 0.25em;
  background: #727981;
  margin-right: 0.125em;
  height: 50%;
  animation-name: levels-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-levels .rai-bar:last-child {
    margin-right: 0; }
